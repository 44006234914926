import UserProfile from '../UserProfile/UserProfile.vue'

export default {
  name: 'InfoMultibanco',
  components: {
    UserProfile,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {

  },
  computed: {

  },
}
